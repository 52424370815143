import AmountInput from '@/components/input/amount-input/amount-input.vue'

// 资产信息表格
export const assetsInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      minWidth: '50px'
    },
    {
      label: '资产类型',
      prop: 'assetType',
      width: '190',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '是否已抵押',
      prop: 'mortgageStatus',
      width: '190',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '权证号',
      prop: 'warrantNumber',
      HiddenOverflow: true,
      minWidth: 100,
      showOverflowTooltip: false
    },
    {
      label: '权属人',
      prop: 'owner',
      minWidth: 150,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '是否实控个人资产',
      prop: 'isController',
      width: '190',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '坐落/明细',
      prop: 'address',
      minWidth: 150,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '评估价',
      prop: 'appraisalPrice',
      HiddenOverflow: true,
      minWidth: 150,
      showOverflowTooltip: false
    },
    {
      label: '贷款余额（元）',
      prop: 'loanBalance',
      HiddenOverflow: true,
      minWidth: 150,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '60px'
    }
  ]
}
// 外部地产情况
export const outPropertyTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '名称',
      prop: 'name',
      width: '200',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '在建项目数（个）',
      prop: 'constructionProjectsNumber',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '签约总额（元）',
      prop: 'totalContractAmount',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '应收结算款总额（元）',
      prop: 'totalReceivablesSettlements',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '业务占比（%）',
      prop: 'businessProportion',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false

    },
    {
      label: '付款方式',
      prop: 'paymentType',
      width: '215',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '付款周期',
      prop: 'paymentCycle',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '抵房款情况',
      prop: 'arrivalPayment',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '负面舆情',
      prop: 'negativePublicOpinion',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '备注',
      prop: 'remark',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      width: '60px',
      fixed: 'right'
    }
  ]
}

// 负债情况
export const liabilitiesInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '借款人',
      prop: 'borrower',
      minWidth: 130,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '借款机构名称',
      prop: 'borrowingOrg',
      minWidth: 170,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '是否实控人个人借款',
      prop: 'isController',
      HiddenOverflow: true,
      width: '190',
      showOverflowTooltip: false
    },
    {
      label: '合同金额（元）',
      prop: 'contractAmount',
      minWidth: 130,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '贷款余额（元）',
      prop: 'loanBalance',
      minWidth: 130,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '到期日',
      prop: 'expiryDate',
      HiddenOverflow: true,
      showOverflowTooltip: false,
      width: '170'
    },
    {
      label: '融资类型',
      prop: 'financingType',
      HiddenOverflow: true,
      showOverflowTooltip: false,
      width: '170'
    },
    {
      label: '担保措施',
      prop: 'guaranteeMeasures',
      HiddenOverflow: true,
      showOverflowTooltip: false,
      width: '170'
    },
    {
      label: '月供',
      prop: 'monthlyPayment',
      minWidth: 100,
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      width: '60px'
    }
  ]
}
// 诉讼情况
export const litigationInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '案号',
      prop: 'caseNumber',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '案由',
      prop: 'causeAction',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '标的金额（元）',
      prop: 'targetAmount',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '案件状态',
      prop: 'caseStatus',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '附件',
      prop: 'finFileInfos',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '备注',
      prop: 'remark',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '操作',
      prop: 'action',
      width: '100px'
    }
  ]
}
export const fromConfig = (content) => {
  const pickerOptions = {
    disabledDate (v) {
      return v.getTime() <= new Date('2009-12-31') || v.getTime() >= new Date()
    }
  }
  return [
    {
      label: '年份:',
      prop: 'year',
      rules: [{
        required: true,
        message: '请选择年份',
        trigger: 'change'
      }],
      attrs: {
        type: 'year',
        placeholder: '请选择',
        'picker-options': pickerOptions
      },
      span: 24,
      tag: 'el-date-picker',
      on: {
        change (data) {
          content.changeYaer(data)
        }
      }
    },
    {
      label: '货币资金:',
      prop: 'cashEquivalents',
      rules: [{
        required: true,
        message: '请输入货币资金',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    },
    {
      label: '应收账款:',
      prop: 'accountsReceivable',
      rules: [{
        required: true,
        message: '请输入应收账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '其他应收账款:',
      prop: 'otherReceivables',
      rules: [{
        required: true,
        message: '请输入其他应收账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    },
    {
      label: '预付账款:',
      prop: 'prepayment',
      rules: [{
        required: true,
        message: '请输入预付账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '总资产合计:',
      prop: 'totalAssets',
      rules: [{
        required: true,
        message: '请输入总资产合计',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '总负债合计:',
      prop: 'totalLiability',
      rules: [{
        required: true,
        message: '请输入总负责合计',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '主营业务收入:',
      prop: 'primeOperatingRevenue',
      rules: [{
        required: true,
        message: '请输入主营业务收入',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '净利润:',
      prop: 'netProfit',
      rules: [{
        required: true,
        message: '请输入净利润',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '净利润率(%):',
      prop: 'netProfitRate',
      rules: [{
        required: true,
        message: '请输入净利润率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '资产负债率(%):',
      prop: 'debtAssetRatio',
      rules: [{
        required: true,
        message: '请输入资产负债率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '应收账款周转率(%):',
      prop: 'receivablesTurnoverRatio',
      rules: [{
        required: true,
        message: '请输入应收账款周转率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '现金短债比:',
      prop: 'cashRatio',
      rules: [{
        required: true,
        message: '请输入现金短债比',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }
  ]
}
