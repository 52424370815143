//外部地产情况
<template>
  <div class="assetsInfo">
    <div class="head">
      <div class="addbutton" @click="addPropertyInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="propertyInfocolumn"
      :tableAttrs="{
        data: propertyInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="nameHeader">
        <font style="color: #ee1c1c">*</font>名称
      </template>
       <template slot="constructionProjectsNumberHeader">
        <font style="color: #ee1c1c">*</font>在建项目数（个）
      </template>
       <template slot="totalContractAmountHeader">
        <font style="color: #ee1c1c">*</font>签约总额（元）
      </template>
       <template slot="totalReceivablesSettlementsHeader">
        <font style="color: #ee1c1c">*</font>应收结算款总额（元）
      </template>
       <template slot="businessProportionHeader">
        <font style="color: #ee1c1c">*</font>业务占比（%）
      </template>
      <template slot="paymentTypeHeader">
        <font style="color: #ee1c1c">*</font>付款方式
      </template>
      <template slot="paymentCycleHeader">
        <font style="color: #ee1c1c">*</font>付款周期
      </template>
      <!-- 名称 -->
       <template slot="name" slot-scope="scope">
        <el-input
          v-model="scope.row.name"
          placeholder="请输入名称"
          maxlength="10"
        ></el-input>
      </template>
      <!-- 在建项目数（个） -->
       <template slot="constructionProjectsNumber" slot-scope="scope">
        <amount-iput
          v-model="scope.row.constructionProjectsNumber"
          placeholder="请输入"
          maxlength="16"
          type="K"
        ></amount-iput>
      </template>
      <!-- 签约总额（元） -->
       <template slot="totalContractAmount" slot-scope="scope">
        <amount-iput
          v-model="scope.row.totalContractAmount"
          placeholder="请输入"
          type="1000Y"
        ></amount-iput>
      </template>
      <!-- 应收结算款总额（元） -->
       <template slot="totalReceivablesSettlements" slot-scope="scope">
        <amount-iput
          v-model="scope.row.totalReceivablesSettlements"
          placeholder="请输入"
          type="1000Y"
        ></amount-iput>
      </template>
      <!-- 业务占比（%） -->
       <template slot="businessProportion" slot-scope="scope">
        <amount-iput
          v-model="scope.row.businessProportion"
          placeholder="请输入"
          type="BL"
        ></amount-iput>
      </template>
      <!-- 付款方式 -->
       <template slot="paymentType" slot-scope="scope">
         <base-select
          v-model="scope.row.paymentType"
          style="width: 200px; margin-right: 10px"
          :options="paymentTypeOption"
          :selectedField="['dictId', 'dictName']"
        ></base-select>

      </template>
      <!-- 付款周期 -->
       <template slot="paymentCycle" slot-scope="scope">
        <el-input
          v-model="scope.row.paymentCycle"
          placeholder="请输入"
          maxlength="20"
        ></el-input>
      </template>
      <!-- 抵房款情况 -->
       <template slot="arrivalPayment" slot-scope="scope">
        <el-input
          v-model="scope.row.arrivalPayment"
          placeholder="请输入"
           maxlength="500"
        ></el-input>
      </template>
      <!-- 负面舆情 -->
       <template slot="negativePublicOpinion" slot-scope="scope">
        <el-input
          v-model="scope.row.negativePublicOpinion"
          placeholder="请输入"
           maxlength="500"
        ></el-input>
      </template>
       <!-- 备注 -->
       <template slot="remark" slot-scope="scope">
        <el-input
          v-model="scope.row.remark"
          placeholder="请输入"
          maxlength="500"
        ></el-input>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="delAssetsItem(scope.$index)"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { outPropertyTable } from '../utils/enterprise-info-config'
import AmountIput from '@/components/input/amount-input/amount-input.vue'
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/base-select.vue'

export default {
  props: {
    tableData: Array
  },
  components: { baseTable, IconButton, AmountIput, BaseSelect },
  data () {
    return {
      // propertyInfoData: [] // 资产信息表格
    }
  },
  computed: {
    propertyInfocolumn () {
      return outPropertyTable(this)
    },
    paymentTypeOption () {
      return getDictLists('PAYMENT_TYPE')
    },
    propertyInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changePropertyInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delAssetsItem (index) {
      this.propertyInfoData.splice(index, 1)
    },
    addPropertyInfo () {
      this.propertyInfoData.push({ add: true, businessId: this.$route.query.businessId })
    }
  }
}
</script>
<style lang="scss" scoped>
.assetsInfo {
  padding: 10px;
}
.assestArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
